import { polyfill as smoothscrollPolyfill } from 'seamless-scroll-polyfill';
// TODO: These will always be used now. We can use {shouldPolyfill} to polyfill only when necessary/
import '@formatjs/intl-listformat/polyfill';
import '@formatjs/intl-listformat/locale-data/en';
import '@formatjs/intl-listformat/locale-data/nl';
import '@formatjs/intl-listformat/locale-data/de';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/nl';
import '@formatjs/intl-pluralrules/locale-data/de';
import 'intersection-observer';
import 'array-flat-polyfill';
/**
 * This files runs at the very beginning (even before React and Next.js core)
 */

smoothscrollPolyfill();
